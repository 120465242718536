function analyticsTracking(type, data) {

    if (type == null || data == null) {
        return false;
    }

    jQuery.ajax({
        type : 'POST',
        dataType : 'json',
        url : analyticsAjax.ajaxurl,
        data : {
            action: 'saveAnalyticsData',
            type: type,
            data: data
        },
    });

}
